import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
const EmojiBtn = ({ text = "like" }) => {
  const [copied, setCopied] = useState(false);
  const [count, setCounter] = useState(5);
  const [isIncrement, setIsIncrement] = useState(true);

  const handleCounter = () => {
    if (isIncrement) {
      setCounter((prevCount) => prevCount + 1);
    } else {
      setCounter((prevCount) => prevCount - 1);
    }
    setIsIncrement(!isIncrement);
  };

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        setCopied(true);

        setTimeout(() => setCopied(false), 2000);
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  return (
    <>
      {" "}
      <div className="pt-6 mx-1">
        <motion.a
          whileHover={{ borderColor: "rgba(218, 218, 218, 0.2)" }}
          className=" select-none inline text-text   text-lg border-2 border-customBorder rounded-[30px] px-8 py-[14px]  cursor-pointer"
        >
          {text === "like" ? (
            <span
              onClick={handleCounter}
              className={`${isIncrement ? "" : "animate-pulse"}  `}
            >
              👏 <span className="ml-2">+{count}</span>
            </span>
          ) : text === "share" ? (
            <span onClick={handleClick}>{copied ? "Copied!" : "Share 🚀"}</span>
          ) : text === "donate" ? (
            <Link to="https://checkout.revolut.com/pay/a4e3fdfb-e8ab-468e-a819-660030af4331 ">
              Support 💛
            </Link>
          ) : text === "collab" ? (
            <Link to="/contact">Like the Idea? 💡</Link>
          ) : (
            "😊"
          )}

          {/* <span className="ml-2">1</span> */}
        </motion.a>
      </div>
    </>
  );
};

export default EmojiBtn;

import React, { useRef, useState } from "react";
import { FaQuoteRight } from "react-icons/fa";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const reviewData = [
  {
    name: "Agha Danish Mehdi",
    workPlace: "",
    role: "PhD Researcher",
    link: "#",
    desc: "I found Mushraf's expertise invaluable. His solutions are not only technically sound but also tailored to complex needs. Highly recommended for anyone in need of top-notch tech support!",
  },
  {
    name: "Jove",
    workPlace: "JOVEGE",
    link: "#",
    desc: "Outstanding work on our 3D website! The attention to detail and creativity in the design exceeded our expectations. The interactive elements are smooth and engaging, making our site stand out.",
  },
  {
    name: "Jay Kumar",
    workPlace: "RakBank",
    link: "https://rakbank.ae",
    role: "SOC Mngr. ",
    desc: "Exceptional cybersecurity tooling and contribution for SOC automation",
  },
];

const ReviewSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };

  // Define CSS classes for different scenarios
  const customOverlayClass =
    currentSlide === 0
      ? "custom-right-overlay"
      : currentSlide === 1
      ? "custom-right-overlay"
      : "custom-left-overlay";

  return (
    <>
      <Swiper
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        spaceBetween={20}
        navigation={true}
        className={`w-full ${customOverlayClass} reviewSlider`}
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1.5,
          },
          1200: {
            slidesPerView: 2,
          },
        }}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => {}}
      >
        {reviewData.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className={`border-2 border-customBorder rounded-3xl p-10 font-primary text-text `}
            >
              <FaQuoteRight size={25} />
              <p className="text-text text-lg font-normal py-8">
                "{item.desc}"
              </p>
              <h6 className="text-text text-lg font-normal">{item.name}</h6>
              <p className="text-[#6a6a6a] text-base">
                {item.role}{" "}
                <a
                  href={item.link}
                  target="_blank"
                  className="underline text-text capitalize cursor-pointer "
                  rel="noreferrer"
                >
                  {item.workPlace}
                </a>
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ReviewSlider;

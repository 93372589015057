import React from "react";
import { BsCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import Reveal from "../utils/Reveal";
import Title from "../utils/Title";
import { BlogData } from "./Data";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center pb-32 md:pb-20">
      <Helmet>
        <title>Blog Posts</title>
      </Helmet>
      <Reveal>
        <Title text={"Thoughts"} />
      </Reveal>
      {/* <div className="flex-row flex w-10/12 gap-2"> */}
      {/* <div style={{ flex: 0.5 }} className="flex flex-col"> */}
      {BlogData.slice()
        .reverse()
        .map((item, index) => (
          <Link to={item.link} key={index}>
            <Reveal box={true} index={index}>
              <div className="flex flex-col justify-start items-center gap-2 sm:my-6 my-4 max-w-[650px]  border-2 border-customBorder rounded-2xl p-[6px] cursor-pointer custom-work-hover">
                <img
                  src={item.img}
                  alt={item.name}
                  className=" rounded-2xl  "
                />
                <div className="text-center font-primary">
                  <h4 className="text-lg text-text my-1">{item.name}</h4>
                  <div className="text-second flex justify-center items-center gap-2 text-xs mb-4 ">
                    <p>{item.category}</p>
                    <BsCircleFill size={4} />
                    <p>{item.date}</p>
                  </div>
                </div>
              </div>
            </Reveal>
          </Link>
        ))}
      {/* </div> */}
      {/* <p className="text-text font-normal text-lg">Step 3</p> */}
      {/* </div> */}
    </div>
  );
};

export default Blog;

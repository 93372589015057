import React from "react";

const Paragraph = ({ children, text }) => {
  return (
    <p className="text-text font-primary text-[17px] leading-[26px] font-normal text-left">
      {children}
      {text}
    </p>
  );
};

export default Paragraph;

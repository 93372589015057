/* eslint-disable react/style-prop-object */
import React from "react";

import Paragraph from "../../utils/Paragraph";
import SemiTitle from "../../utils/SemiTitle";
import Title from "../../utils/Title";
import { BlogData } from "../Data";
import SinglePost from "../../utils/SinglePost";
import EmojiBtn from "../../utils/EmojiBtn";
import ButtonVisit from "../../utils/ButtonVisit.";
import { Helmet } from "react-helmet";

const GPSCannon = () => {
  return (
    <>
      <Helmet>
        <title>{BlogData[3].name}</title>
        <meta name="description" content={BlogData[3].category} />
      </Helmet>
      <SinglePost
        postTitle={BlogData[3].name}
        Postimg={BlogData[3].img}
        postDate={BlogData[3].date}
      >
        <SemiTitle text={"What is this innovation??"} />
        <Paragraph
          text="
        In today’s fast-paced environment, law enforcement faces growing challenges in maintaining public safety, especially during high-speed pursuits. A vehicle pursuit not only endangers officers but also the public, requiring safer alternatives to traditional methods."
        />
        <Paragraph>
          To address this,i am proposing an innovative standalone{" "}
          <b>GPS dart system</b>, designed to make police pursuits safer and
          more efficient.
        </Paragraph>

        <SemiTitle text={"Concept Behind"} />
        <Paragraph
          text={
            "The GPS tracking dart is a compact, self-contained unit that can be launched from a pursuing vehicle onto a fleeing one. Once attached, it provides real-time location tracking without requiring risky high-speed chases. This allows officers to monitor suspects remotely, planning safer and more strategic apprehensions."
          }
        />
        <Title text={"Features"} style={"m-10"} />

        <SemiTitle text={"Long-Range Wireless Communication"} />

        <Paragraph>
          <ul>
            <li>
              Reliable Communication Incorporates&nbsp;
              <a
                className="text-yellow-600 font-bold"
                href="https://en.wikipedia.org/wiki/LoRa"
              >
                # LoRa (Long Range)&nbsp;
              </a>
              technology for tracking up to several kilometers. ( without GSM or
              internet dependency ❌)
            </li>

            <li>
              Ideal for environments with low signal interference, such as rural
              areas or terrin.
            </li>
          </ul>
        </Paragraph>

        <SemiTitle text={"Design and Efficient Adhesion Mechanism"} />
        <Paragraph>
          <ul>
            <li>
              Compact design to securely attach and ensuring stability even at
              high speeds and during sudden maneuvers.
            </li>
            <li>
              Would used magnetic mounts or gel-based adhesives to attach
              securely to vehicles without causing damage.
            </li>
          </ul>
        </Paragraph>
        <SemiTitle text={"Real-Time Tracking Software"} />
        <Paragraph>
          <ul>
            <li>
              Live Location will be sent to receiver LoRa module and can be
              accessed through a central C2 interface.
            </li>
            <li>
              Features such as geofencing, speed alerts, and route history can
              be added to software.
            </li>
          </ul>
        </Paragraph>

        <SemiTitle text={"Adaptable Deployment System"} />
        <Paragraph>
          <ul>
            <li>
              Mounted launcher integrated into police vehicles, with an optional
              <span className="text-emerald-500"> handheld</span> unit for
              flexibility
            </li>
            <li>
              <strong>LATER:</strong>I have feature in mind like laser targeting
              which will help even in challenging conditions.
            </li>
          </ul>
        </Paragraph>

        <Title text={"Benefits"} style={"m-10"} />

        <Paragraph>
          Reduces the need for high-risk pursuits, minimizing accidents and
          collateral damage, while allows officers to focus on strategic
          interception rather than high-speed following.
          <ul className="border-amber-300 border-r-4 rounded-md mt-2">
            <li className="underline text-xl ">Scenerio: 1 </li>
            <span>
              Reduced helicopter deployments, a single helicopter hour can cost
              heavely depending on the region and model. Using this GPS tracker,
              police can avoid deploying helicopters for tracking suspects,
              significantly reducing operational expenses.{" "}
            </span>
          </ul>
          <ul className="border-green-300 border-r-4 rounded-md my-2">
            <li className="underline text-xl ">Scenerio: 2 </li>
            <span>
              High-speed chases are fuel-intensive. This system will allow
              vehicles to maintain a safer speed while keeping track of suspects
              remotely.
            </span>
          </ul>
          Other than that it has broad applicability useful in different
          environments and adaptable to various vehicle types.
        </Paragraph>
        <SemiTitle text={"Collaborative Potential 🌟"} />
        <Paragraph
          text={
            "I am seeking partnerships with law enforcement agencies, municipalities, and technology innovators in Sweden to test and refine this product. By working together, we can revolutionize how police operations handle vehicle pursuits, setting new standards for safety and efficiency."
          }
        />
        <SemiTitle text={"Get Involved 🤝"} />
        <Paragraph
          text={
            "I invite interested parties to collaborate on this project. Whether you’re part of a law enforcement agency, public safety organization, or an R&D institute, your feedback and involvement can help bring this innovation to life."
          }
        />

        <Paragraph
          text={
            "Get in touch with me to explore pilot programs, provide feedback, or discuss implementation opportunities. Let’s work together to make our streets safer and smarter."
          }
        />
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            {/* <ButtonVisit text="Feedback?" /> */}
            <EmojiBtn text="like" />
            <EmojiBtn text="collab" />
            <EmojiBtn text="share" />
          </div>
          <EmojiBtn text="donate" />
        </div>
      </SinglePost>
    </>
  );
};

export default GPSCannon;

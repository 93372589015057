import Design from "../assets/design.jpg";
import Sketch from "../assets/sketch.jpg";
import Wireframe from "../assets/wireframe.jpg";
export const BlogData = [
  {
    img: "/me/hackrf.jpg",
    name: "HackRF - Common Use Cases ",
    category: "#Hacking #SDR",
    date: "09/01/2024",
    link: "HackRf-attacks",
  },
  {
    img: "/me/xserver.webp",
    name: "X11 Forwasrding - Simplified Remote  GUI Execution",
    category: "#Tricks #Linux",
    date: "12/04/2024",
    link: "x11-forwarding",
  },
  {
    img: "/me/AIV.png",
    name: "AI Enhanced Vehicle System - Securing the Future",
    category: "#Privacy #AI",
    date: "24/06/2024",
    link: "/projects/AIEV",
  },

  {
    img: "/me/gpstracker.jpg",
    name: "Innovative GPS Tracking Solution for Modern Law Enforcement",
    category: "#GPS #Concepts",
    date: "26/11/2024",
    link: "/projects/gps-cannon",
  },
];

/* eslint-disable react-hooks/rules-of-hooks */
import { motion } from "framer-motion";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

import { Link } from "react-router-dom";
import girl from "../assets/cyybergenius_03.jpeg";
import { Helmet } from "react-helmet";

const data = [
  {
    id: "A",
    title: "I heard you're the go-to person for cybersecurity!",
    link: "work",
  },
  {
    id: "B",
    // title: "People are talking about your top-tier security expertise!",
    // title: "I like reading about web technologies you working on!"
    title: "I'd love reading about the tech you're developing!",
    link: "blog",
  },
  { id: "C", title: "Mushraf, who?!", link: "about" },
];

const Home = () => {
  const [hover, setHover] = useState(false);

  return (
    <motion.div
      initial={{ paddingTop: "150px" }}
      animate={{ paddingTop: "0px" }}
      transition={{ delay: 0.9, duration: 3 }}
      className=" min-h-screen flex justify-center items-center flex-col pb-10 md:pb-0"
    >
      <Helmet>
        <title>Welcome - Mushraf.com </title>
      </Helmet>
      <div className="text-center font-primary">
        <h2 className="text-3xl sm:text-[40px] text-text font-light">
          <motion.span
            initial={{ opacity: 0, x: -50, y: -50 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            transition={{ delay: 0.1, duration: 1 }}
          >
            Hey,
          </motion.span>{" "}
          <motion.span
            initial={{ opacity: 0, x: -50, y: -50 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
          >
            I’m
          </motion.span>
          <motion.img
            initial={{ opacity: 0, x: 10, y: 10 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            transition={{ delay: 0.3, duration: 1 }}
            src={"/me/me3.jpg"}
            alt="intro-img"
            nodra
            width={75}
            height={65}
            className="rounded-full h-[75px] object-left-top inline ml-5 mr-2 object-cover"
          />{" "}
          <motion.span
            initial={{ opacity: 0, x: -50, y: -50 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            transition={{ delay: 0.4, duration: 1 }}
          >
            Mushraf
          </motion.span>
        </h2>
        <h2 className="text-3xl sm:text-[40px] text-text pt-4 font-light">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 2 }}
          >
            How can I help you?
          </motion.span>
        </h2>
      </div>
      <div className="py-8">
        {data.map((item, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2 + index / 5, duration: 2 }}
          >
            <Link
              to={item.link}
              key={index}
              className="flex  font-primary items-center justify-between my-4 py-4 border-2 border-customBorder rounded-lg sm:min-w-[470px] cursor-pointer"
              onMouseEnter={() => setHover(item.id)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="flex items-center text-sm">
                <p className="text-second px-5">{item.id}</p>
                <p
                  className={`${
                    hover !== item.id ? "text-text" : "text-second"
                  }`}
                >
                  {item.title}
                </p>
              </div>
              <div
                className={`px-5 ${
                  hover === item.id ? "text-second" : "text-text"
                }`}
              >
                {hover === item.id ? (
                  <FiArrowRight size={18} />
                ) : (
                  <FiArrowRight size={18} />
                )}
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
      <div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 4.5, duration: 3 }}
          className="text-center font-primary text-text text-xs"
        >
          Never Mind –{" "}
          <Link to="/contact">
            <span className="hoverText after:absolute after:h-[1px] after:bottom-[-1px] after:left-0 border-b-[1px] border-customBorder">
              Just Say Hi
            </span>
          </Link>
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Home;

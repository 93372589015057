/* eslint-disable react/style-prop-object */
import React from "react";

import Paragraph from "../../utils/Paragraph";
import SemiTitle from "../../utils/SemiTitle";
import Title from "../../utils/Title";
import { BlogData } from "../Data";
import SinglePost from "../../utils/SinglePost";
import EmojiBtn from "../../utils/EmojiBtn";
import ButtonVisit from "../../utils/ButtonVisit.";
import { Helmet } from "react-helmet";

const Aievproject = () => {
  return (
    <>
      <Helmet>
        <title>{BlogData[2].name}</title>
        <meta name="description" content={BlogData[2].category} />
      </Helmet>
      <SinglePost
        postTitle={BlogData[2].name}
        Postimg={BlogData[2].img}
        postDate={BlogData[2].date}
        donation
      >
        <SemiTitle text={"Artificial Intelligence Enhanced Vehicle (AIEV)"} />
        <div className="flex flex-row flex-wrap justify-between">
          <Paragraph text={"✨ ANPR SYSTEM "} />
          <Paragraph text={"✨ RADAR SYSTEM "} />
          <Paragraph text={"✨ VEHICLE ANALYZER "} />
        </div>
        <Title text={"About - AIEV Project"} />
        <Paragraph text="The AIEV System is a privacy-oriented, next-generation automobile security solution equipped with an array of advanced sensors designed to protect your vehicle from surveillance, cyber threats, and drone attacks. " />
        <Paragraph
          text={
            " In today's fast-paced world, privacy and security have become paramount concerns, especially for entrepreneurs and  individuals. With evolving threats, both digital and physical, the need for an advanced, intelligent security solution is more critical than ever. The AIEV system addresses these concerns by offering cutting-edge technology that safeguards you and your vehicle from various threats encountered in day-to-day automobile usage."
          }
        />
        <SemiTitle text={"Your Guardian on the Road - ANPR System"} />
        <p>
          ANPR - Automatic Number Plate Recognition System - Camera Data
          Collection
        </p>
        <Paragraph
          text={
            "The first line of defense in the AIEV system is the ANPR system. Tailgating is not just a nuisance; it can also be a potential threat. The ANPR system is designed to detect and record any vehicle that consistently follows yours, whether daily or for an extended period."
          }
        />
        <p className="text-text font-normal text-lg">How it Works</p>
        <div className="text-text text-sm ">
          <ul>
            <li>
              <strong>Detection:</strong>
              &nbsp;Utilizing high-resolution cameras and sophisticated image
              processing algorithms, the ANPR system scans and recognizes
              license plates of vehicles in your vicinity.
            </li>
            <li>
              <strong>Monitoring:</strong>
              &nbsp;AIEV system logs tailgating vehicles, analyzing patterns to
              identify persistent followers.
            </li>{" "}
            <li>
              <strong>Alerting:</strong>
              &nbsp;If a potential threat is detected, the system alerts the
              driver with real-time notifications, enabling prompt action.
            </li>
          </ul>
        </div>
        <p className="text-text font-normal text-lg">Case Study</p>
        <Paragraph
          text={
            "Consider Jane, a high-profile executive who often travels for business. Jane noticed a car that seemed to follow her frequently. With the AIEV system installed, the ANPR system identified and recorded the vehicle's license plate, alerting Jane to the potential threat. This early warning allowed her to take precautionary measures and report the suspicious activity to authorities."
          }
        />
        <div className="w-full flex-col items-center flex justify-center">
          <img src="/me/AIEV.png" className="w-3/4" />
          <p>
            *Figure : ANPR / Radar / Vehicle Analyser With Cloud Integration
          </p>
        </div>

        <SemiTitle text={"Radar Signal Analyzer - Hostile Signal Detection"} />
        <p>Air network Anomalies Detection and Prevention System</p>
        <Paragraph
          text={
            "In the age of digital warfare, network anomalies, WiFi jammers, GPS spoofing, and drone attacks are genuine concerns. The AIEV system’s hostile signal detection feature offers robust protection against these invisible threats."
          }
        />
        <p className="text-text font-normal text-lg">How it Works</p>
        <div className="text-text text-sm ">
          <ul>
            <li>
              <strong>Detection:</strong>
              &nbsp;The system continuously scans for irregularities in network
              signals and identifies the presence of jammers, GPS spoofers, and
              unauthorized drones.
            </li>
            <li>
              <strong>Analysis:</strong>
              &nbsp;Advanced algorithms analyze the threat level, determining
              the nature and proximity of the anomaly.
            </li>{" "}
            <li>
              <strong>Precautionary Actions:</strong>
              &nbsp;Upon detection, the system suggests precautionary actions,
              such as activating anti-drone measures and cutting off the drones'
              communication within range.
            </li>
          </ul>
        </div>
        <p className="text-text font-normal text-lg">Case Study</p>
        <Paragraph
          text={
            "Mark, an entrepreneur, often travels with sensitive business data. One day, his AIEV system detected a jammer and an unauthorized drone nearby. Radar Signal Analyzer feature promptly alerted him and activated the anti-drone system, neutralizing the threat and ensuring his data remained secure."
          }
        />
        {/* //3rd */}
        <SemiTitle text={"Vehicle Analyzer: All about Physical Security"} />
        <p>MULTI-LAYERED DEFENSIVE SOLUTION</p>
        <Paragraph
          text={
            "This feature  is designed to detect any tracking devices that may be placed inside or outside your vehicle, providing peace of mind in knowing your whereabouts remain private. "
          }
        />
        <p className="text-text font-normal text-lg">How it Works:</p>
        <div className="text-text text-sm ">
          <ul>
            <li>
              <strong>Pattern Recognition Algorithms:</strong>
              &nbsp;AIEV algorithms are trained to recognize patterns typical of
              tracking devices. They analyze data from AIEV's and other sensors
              and scanners to differentiate between normal vehicle components
              and potential threats.
            </li>
            <li>
              <strong>Radio Frequency (RF) Scanners:</strong>
              &nbsp;Trackers often transmit data via RF signals. AIEV scans for
              these signals, identifying the presence of transmitting devices.
            </li>{" "}
            <li>
              <strong>Regular Inspections:</strong>
              &nbsp;AIEV performs regular, automated inspections of the
              vehicle's interior and exterior, ensuring comprehensive coverage.
            </li>
          </ul>
        </div>
        <p className="text-text font-normal text-lg">Case Study</p>
        <Paragraph
          text={
            "Jove, a prominent public figure, became concerned about potential tracking. After installing the AIEV system, the Vehicle Analyzer detected a small tracking device hidden in her car's bumper. The early detection allowed Jove to remove the device and enhance her personal security."
          }
        />
        {/* <p className="text-text font-normal text-lg">Step 2</p>
        <Paragraph text={"How to use step - 2 🐛"} />

        <p className="text-text font-normal text-lg">Step 3</p>
        <Paragraph text={"TEEST & DONE 🛠️ "} /> */}
        <SemiTitle text={"Why Choose AIEV?"} />
        <Paragraph
          text={
            "The AIEV system is not just a security solution; it's a proactive guardian. By combining these three advanced AI features, the system provides a comprehensive shield against a range of threats, ensuring your safety and privacy on the road."
          }
        />
        <SemiTitle text={"Project Launch and Development Timeline 🚀"} />
        <Paragraph
          text={
            "The AIEV system is currently under development and is slated for release by Q3 of 2025. Development process is divided into several key phases:"
          }
        />
        <p className="text-text text-sm">
          <strong>Q3 2024 - Q4 2024:</strong>
          &nbsp;development phase, including prototype design and preliminary
          testing.
        </p>
        <p className="text-text text-sm">
          <strong>Q1 2025 - Q3 2025 : </strong>
          &nbsp;beta testing with select users to gather feedback and make final
          adjustments. Finalization of product design, production preparation,
          and marketing strategy.
        </p>

        <p className="text-text text-sm">
          <strong>Q4 2025 🎉</strong>
          &nbsp; Official launch and availability of the AIEV system.
        </p>
        <SemiTitle text={"Support the AIEV Project 🎁 "} />
        <Paragraph
          text={
            "The AIEV project is advancing towards a revolutionary launch in Q4 2025, but I need your support to make it a reality."
          }
        />
        <Paragraph text={""} />
        <p className="text-text text-sm">
          Contribute to fund my research, technology, and production or explore
          corporate sponsorship opportunities for exclusive benefits. For
          fundings and donations please get in touch with me at{" "}
          <a
            href="mailto:contribute@mushraf.com"
            className="text-yellow-200"
            title="Contribute - AIEV Project"
          >
            contribute@mushraf.com
          </a>
        </p>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            {/* <ButtonVisit text="Feedback?" /> */}
            <EmojiBtn text="collab" />
            <EmojiBtn text="share" />
          </div>
          <EmojiBtn text="donate" />
        </div>
      </SinglePost>
    </>
  );
};

export default Aievproject;
